// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")
require("jquery")
require("jquery-ui")
require("./lib/jquery.cookie")
require("bootstrap")
require("packs/lib/sb-admin-2")
require("packs/lib/datetime")
require("packs/lib/3digit_separator")

import "@fortawesome/fontawesome-free/js/all";
import "../src/application_vendor.scss"
import Vue from 'vue'

require("packs/lib/common")

window.addEventListener('DOMContentLoaded', (event) => {
  if ($('#showTermsOfUseModal').length > 0) {
    $('#showTermsOfUseModal,#showTermsOfUseModal2').on('click', () => {
      $('body').addClass('terms-of-use')
      $('#termsOfUseModal').modal('show')
    })
  }
});
