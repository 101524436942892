 require("./jquery.timepicker.min")
 require("./jquery.datetimepicker.full.min")

 $(document).on('turbolinks:load', () => {
  (function ($) {
    $('[type="date"]').datepicker({
      showOtherMonths: true,
      selectOtherMonths: true,
      changeYear: true,
      changeMonth: true,
      dateFormat: 'yy-mm-dd',
      showAnim: "fadeIn"
    }, $.datepicker.regional['ja']).removeAttr('type')

    $('[type="time"]').timepicker({
      'timeFormat': 'H:i',
      'show2400': true,
      'minTime': '00:00:01'
    }).removeAttr('type')

    $('[type="datetime"]').datetimepicker({
      format: 'Y/m/d H:i',
    }).removeAttr('datetime')

    $.datepicker.regional['ja'] = {
      closeText: "閉じる",
      prevText: "&#x3C;前",
      nextText: "次&#x3E;",
      currentText: "今日",
      monthNames: [ "1月","2月","3月","4月","5月","6月",
      "7月","8月","9月","10月","11月","12月" ],
      monthNamesShort: [ "1月","2月","3月","4月","5月","6月",
      "7月","8月","9月","10月","11月","12月" ],
      dayNames: [ "日曜日","月曜日","火曜日","水曜日","木曜日","金曜日","土曜日" ],
      dayNamesShort: [ "日","月","火","水","木","金","土" ],
      dayNamesMin: [ "日","月","火","水","木","金","土" ],
      weekHeader: "週",
      dateFormat: "yy/mm/dd",
      firstDay: 0,
      isRTL: false,
      showMonthAfterYear: true,
	    yearSuffix: "年"
    };
    $.datetimepicker.setLocale('ja');
    $.datepicker.setDefaults($.datepicker.regional['ja']);
  })(jQuery); // End of use strict
 })
