require("packs/lib/yubinbango")

const images = require.context('../../images', true)
const imagePath = (name) => images(name, true)

import flatpickr from "flatpickr";
import { Japanese } from "flatpickr/dist/l10n/ja"
flatpickr.localize(Japanese); // デフォルトの言語が日本語になります

import { DirectUpload } from "@rails/activestorage"

document.addEventListener('turbolinks:load', () => {
  $(".init-date-time").each(function(){
    flatpickr(".init-date-time", {
      minDate: $(this).data('min-date'),
      enableTime: true,
      dateFormat: "Y/m/d H:i",
      disableMobile: false
    })
  })
  $(".init-date").each(function(){
    flatpickr(this, {
      minDate: $(this).data('min-date'),
      enableTime: false,
      dateFormat: "Y/m/d",
      disableMobile: false
    })
  })

  /* pagetop */
  $('a[href^="#"]:not(a.carousel-control-prev, a.carousel-control-next, a.nav-link, a[data-toggle="collapse"], a[data-toggle="tab"])').on('click', function () {
    var href = this.hash;
    if (href == '#pagetop') {
      $('html,body').animate({ scrollTop: 0 }, 'slow', 'swing');
      return false;
    } else {
      if ($(href).length > 0) {
        $('html,body').animate({ scrollTop: $(href).offset().top }, 'slow', 'swing');
      }
      return false;
    }
  });

  /* scroll event */
  $(window).on('scroll', function () {
    var pagetop = $('#pagetop');
    if ($(window).scrollTop() > 300) {
      pagetop.css('display', 'block');
    } else {
      pagetop.css('display', 'none');
    }
  });


  $("#add_line").on("click", function () {
    // テーブルの最終行をクローンしてテーブルの最後に追加する
    $(".parts_processing tbody tr:last-child").clone(true).appendTo(".parts_processing tbody");
    var target_tr_selector = ".parts_processing tbody tr:last-child";
    var target_tr = $(target_tr_selector);
    var name_num = parseInt(target_tr.find("input")[0].name.match(/request\[request_details_attributes\]\[(\d+)\]/)[1],10) + 1;
    target_tr.find(".new-file").each(function(){
      this.id = this.id.replace(/request_request_details_attributes_(\d+)_/,'request_request_details_attributes_'+name_num+'_')
    });
    target_tr.find("input").each(function(){
      this.name = this.name.replace(/request\[request_details_attributes\]\[(\d+)\]/,'request[request_details_attributes]['+name_num+']')
      this.id = this.id.replace(/request_request_details_attributes_(\d+)_/,'request_request_details_attributes_'+name_num+'_')
      if (this.type == 'file') {
        $('#'+this.id+'_signed_id').remove();
        $('#'+this.id+'_new_file_name').remove();
        $('#'+this.id).show();
        if ($(this).hasClass('with_btn')) {
          $(this).parent().find('span.btn').show();
        }
      }
    });
    target_tr.find("label").each(function(){
      $(this).prop('for', $(this).prop('for').replace(/request_request_details_attributes_(\d+)_/,'request_request_details_attributes_'+name_num+'_'))
    });
    var priority = parseInt(target_tr.find("input[name*=priority]").val(),10);
    target_tr.find("input").val("");
    target_tr.find("input[name*=priority]").val(priority + 1);
    target_tr.find("input[name*=amount]").val(0);
    target_tr.find("input[name*=file_count]").val(1);

    flatpickr(target_tr_selector+" .init-date-time", {
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      disableMobile: false
    })
    flatpickr(target_tr_selector+" .init-date", {
      enableTime: false,
      dateFormat: "Y-m-d",
      disableMobile: false
    })
    target_tr.find("input[type=file]").each(function(){
      var input = this
      input.addEventListener('change', (event) => {
        handleUploadFile(input)
      })
    })
    return false;
  });

  $(document).on('change', ':file', function () {
    var input = $(this),
      numFiles = input.get(0).files ? input.get(0).files.length : 1,
      label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
    input.parent().parent().prev(':text').val(label);
  });


  const uploadFile = (input, file) => {
    return new Promise((resolve, reject) => {
      // フォームではfile_field direct_upload: trueが必要
      // （これでdata-direct-upload-urlを提供する）
      const url = input.dataset.directUploadUrl
      const upload = new DirectUpload(file, url)

      upload.create((error, blob) => {
        if (error) {
          // エラーハンドリングをここに書く
          console.log('--- error start')
          console.log(error)
          console.log('--- error end')
          reject()
        } else {
          console.log('--- blob start')
          console.log(blob)
          console.log('--- blob end')
          // 名前が似ているhidden inputをblob.signed_idの値とともにフォームに追加する
          // これによりblob idが通常のアップロードフローで転送される
          if ($(input).prop('multiple')) {
            const hiddenField = document.createElement('input')
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("id", input.getAttribute("id") + '_signed_ids_' + blob.signed_id)
            hiddenField.name = input.name.replace(/\[(.*)\]\[\]/, '[$1_signed_ids][]')
            hiddenField.setAttribute("value", blob.signed_id);

            input.closest('form').appendChild(hiddenField)
          } else {
            const hiddenField = document.createElement('input')
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("id", input.getAttribute("id") + '_signed_id')
            hiddenField.name = input.name.replace(/\[(.*)\]/, '[$1_signed_id]')
            hiddenField.setAttribute("value", blob.signed_id);

            if (!document.getElementById(input.getAttribute("id") + '_signed_id')) {
              input.closest('form').appendChild(hiddenField)
            }
          }
          resolve({filename: blob.filename, signed_id: blob.signed_id})
        }
      })
    })
  }

  function removeInvalidFeedback(input) {
    if ($(input).data('scope')) {
      $(input).closest($(input).data('scope')).find('.invalid-feedback').remove();
    } else {
      $(input).parent().find('.invalid-feedback').remove();
    }
  }

  async function handleUploadFile(input) {
    const loading = $('<div>アップロード中...</div>')
    try {
      removeInvalidFeedback(input);
      $(input).parent().find('.upload-error').remove();
      // hide input
      input.style.display = 'none'
      if ($(input).hasClass('with_btn')) {
        $(input).parent().find('span.btn').hide();
      }
      $(input).parent().prepend(loading);
      const uploadedFiles = await Promise.all(Array.from(input.files).map(file => uploadFile(input, file)))
      if ($(input).prop('multiple')) {
        $('#'+input.id).show();
        if ($('#'+input.id).hasClass('with_btn')) {
          $('#'+input.id).parent().find('span.btn').show();
        }
        $.each(uploadedFiles,function(index,file){
          $(input).parents('div.direct_upload_file').append(`<div class="new-file" id="${input.id}_new_file_name_${file.signed_id}">${file.filename}<div class="btn btn-sm"><i class="fas fa-times-circle"></i></div></div>`);
          $('#'+input.id+'_new_file_name_'+file.signed_id+' .btn').on("click", function () {
            removeInvalidFeedback(input);
            $('#'+input.id+'_signed_ids_'+file.signed_id).remove();
            $('#'+input.id+'_new_file_name_'+file.signed_id).remove();
          });
        });
      } else {
        $(input).parents('div.direct_upload_file').prepend(`<div class="new-file" id="${input.id}_new_file_name">${uploadedFiles[0].filename}<div class="btn btn-sm"><i class="fas fa-times-circle"></i></div></div>`);
        $('#'+input.id+'_new_file_name .btn').on("click", function () {
          removeInvalidFeedback(input);
          $('#'+input.id+'_signed_id').remove();
          $('#'+input.id+'_new_file_name').remove();
          $('#'+input.id).show();
          if ($('#'+input.id).hasClass('with_btn')) {
            $('#'+input.id).parent().find('span.btn').show();
          }
        });
        var registered_file_signed_id = $(input).parents('div.direct_upload_file').parent().find('.registered_file_signed_id')
        if (registered_file_signed_id.length>0) {
          $.each(registered_file_signed_id,function(index,file_signed_id){
            $('#'+input.id+'_destroy_signed_id_'+$(file_signed_id).val()).val('');
          });
        }
      }
      // 選択されたファイルを入力からクリアしておく
      input.value = null
      loading.remove()
    } catch (error) {
      input.value = null
      loading.remove()
      // show input
      input.style.display = 'block'
      $(input).parent().prepend('<div class="upload-error">アップロードに失敗しました。</div>');
    }
  }

  $('input[type=file]').each(function(){
    var input = this

    if ($(input).hasClass('with_btn')) {
      $([input].concat($(input).parent().find('.invalid-feedback').get())).wrapAll(`<label for="${input.id}"></label>`)
      $(input).before('<span class="btn btn-success">ファイルを選択</span>')
    }

    var td = $($(input).parents('div.direct_upload_file'))
    if ($(input).prop('multiple')) {
      var file_signed_ids = td.parent().find('.file_signed_ids')
      if (file_signed_ids.length>0) {
        $.each(file_signed_ids,function(index,file_signed_id){
          var file_name = $(file_signed_id).parent().find('.file_signed_ids_filename')
          td.append(`<div class="new-file" id="${input.id}_new_file_name_${$(file_signed_id).val()}">${file_name.val()}<div class="btn btn-sm"><i class="fas fa-times-circle"></i></div></div>`);
          $('#'+input.id+'_new_file_name_'+$(file_signed_id).val()+' .btn').on("click", function () {
            removeInvalidFeedback(input);
            $('#'+input.id+'_signed_ids_'+$(file_signed_id).val()).remove();
            $('#'+input.id+'_new_file_name_'+$(file_signed_id).val()).remove();
          });
        });
      }
      var registered_file_signed_ids = td.parent().find('.registered_file_signed_ids')
      if (registered_file_signed_ids.length>0) {
        $.each(registered_file_signed_ids,function(index,file_signed_id){
          var file_name = $(file_signed_id).parent().find('.file_signed_ids_filename')
          var url = $(file_signed_id).parent().find('.file_signed_ids_url').val()
          var html = '<div class="new-file" id="'+input.id+'_new_file_name_'+$(file_signed_id).val()+'">'
          if (url) {
            html += '<a href="'+url+'">'+file_name.val()+'</a>';
          } else {
            html += file_name.val();
          }
          html += '<div class="btn btn-sm"><i class="fas fa-times-circle"></i></div></div>';
          td.append(html);
          $('#'+input.id+'_new_file_name_'+$(file_signed_id).val()+' .btn').on("click", function () {
            removeInvalidFeedback(input);
            $('#'+input.id+'_destroy_signed_ids_'+$(file_signed_id).val()).val($(file_signed_id).val());
            $('#'+input.id+'_signed_ids_'+$(file_signed_id).val()).remove();
            $('#'+input.id+'_new_file_name_'+$(file_signed_id).val()).remove();
          });
        });
      }
    } else {
      var file_signed_id = td.parent().find('.file_signed_id')
      if (file_signed_id.length>0) {
        input.style.display = 'none'
        if ($(input).hasClass('with_btn')) {
          td.find('label span.btn').hide();
        }
        var file_name = td.parent().find('.file_signed_id_filename')
        td.prepend(`<div class="new-file" id="${input.id}_new_file_name">${file_name.val()}<div class="btn btn-sm"><i class="fas fa-times-circle"></i></div></div>`);
        $('#'+input.id+'_new_file_name .btn').on("click", function () {
          removeInvalidFeedback(input);
          $('#'+input.id+'_signed_id').remove();
          $('#'+input.id+'_new_file_name').remove();
          $('#'+input.id).show();
          if ($('#'+input.id).hasClass('with_btn')) {
            $('#'+input.id).parent().find('span.btn').show();
          }
        });
      }
      var registered_file_signed_id = td.parent().find('.registered_file_signed_id')
      if (registered_file_signed_id.length>0) {
        $.each(registered_file_signed_id,function(index,file_signed_id){
          var file_name = $(file_signed_id).parent().find('.file_signed_id_filename')
          var url = $(file_signed_id).parent().find('.file_signed_id_url').val()
          var html = '<div class="new-file" id="'+input.id+'_new_file_name_'+$(file_signed_id).val()+'">'
          if (url) {
            html += '<a href="'+url+'">'+file_name.val()+'</a>';
          } else {
            html += file_name.val();
          }
          html += '<div class="btn btn-sm"><i class="fas fa-times-circle"></i></div></div>';
          $(input).hide();
          td.append(html);
          $('#'+input.id+'_new_file_name_'+$(file_signed_id).val()+' .btn').on("click", function () {
            removeInvalidFeedback(input);
            $(input).show();
            $('#'+input.id+'_destroy_signed_id_'+$(file_signed_id).val()).val($(file_signed_id).val());
            $('#'+input.id+'_new_file_name_'+$(file_signed_id).val()).remove();
          });
        });
      }
    }
    // ファイルドロップへのバインド: 親要素のondropか、
    // Dropzoneなどのライブラリを使う
    /*
    const onDrop = (event) => {
      event.preventDefault()
      const files = event.dataTransfer.files;
      Array.from(files).forEach(file => uploadFile(file))
    }
    */

    // 通常のファイル選択へのバインド
    input.addEventListener('change', (event) => {
      handleUploadFile(input)
    })
  })

  $("#add_cost_line").on("click", function () {
    // テーブルの最終行をクローンしてテーブルの最後に追加する
    var parent_td = $(this).closest("td");
    parent_td.find(".costs .cost_row:last-child").clone(true).appendTo(parent_td.find(".costs"));
    var target_row = parent_td.find(".costs .cost_row:last-child");
    var name_num = parseInt(target_row.find("input")[0].name.match(/\[estimate_work_log_costs_attributes\]\[(\d+)\]/)[1],10) + 1;
    target_row.find("select,input").each(function(){
      this.name = this.name.replace(/\[estimate_work_log_costs_attributes\]\[(\d+)\]/,'[estimate_work_log_costs_attributes]['+name_num+']')
      this.id = this.id.replace(/estimate_work_log_costs_attributes_attributes_(\d+)_/,'restimate_work_log_costs_attributes_attributes_'+name_num+'_')
    });
    target_row.find("label").each(function(){
      $(this).prop('for', $(this).prop('for').replace(/estimate_work_log_costs_attributes_attributes_(\d+)_/,'estimate_work_log_costs_attributes_attributes_'+name_num+'_'))
    });
    target_row.find("input").val("");

    return false;
  });
})
